import { StrictMode, lazy, Suspense } from 'react'
import { createRoot } from 'react-dom/client'

import { kcContext as kcLoginThemeContext } from './keycloak-theme/login/kcContext'
import { kcContext as kcAccountThemeContext } from './keycloak-theme/account/kcContext'

declare global {
  interface Window {
    $gtag: any
    $ym: any
    dataLayer: any
    gtag: any
    ym: any
  }
}

const KcLoginThemeApp = lazy(() => import('./keycloak-theme/login/KcApp'))
const KcAccountThemeApp = lazy(() => import('./keycloak-theme/account/KcApp'))
const App = lazy(() => import('./App'))

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <Suspense>
      {(() => {
        if (kcLoginThemeContext !== undefined) {
          return <KcLoginThemeApp kcContext={kcLoginThemeContext} />
        }

        if (kcAccountThemeContext !== undefined) {
          return <KcAccountThemeApp kcContext={kcAccountThemeContext} />
        }

        return <App />
      })()}
    </Suspense>
  </StrictMode>
)
