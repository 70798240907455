import { createGetKcContext } from 'keycloakify/login'

export type KcContextExtension =
  | { pageId: 'login.ftl' }
  | { pageId: 'register.ftl'; authorizedMailDomains: string[]; properties: { hasuraApiUrl: string; termsUrl: string } }
  | { pageId: 'login-verify-email.ftl'; url: { loginRestartFlowUrl?: string } }

//NOTE: In most of the cases you do not need to overload the KcContext, you can
// just call createGetKcContext(...) without type arguments.
// You want to overload the KcContext only if:
// - You have custom plugins that add some values to the context (like https://github.com/micedre/keycloak-mail-whitelisting that adds authorizedMailDomains)
// - You want to add support for extra pages that are not yey featured by default, see: https://docs.keycloakify.dev/contributing#adding-support-for-a-new-page

export const { getKcContext } = createGetKcContext<KcContextExtension>({
  mockData: [
    {
      pageId: 'error.ftl',
      locale: { currentLanguageTag: 'ru' }
    },
    {
      pageId: 'info.ftl',
      locale: { currentLanguageTag: 'ru' },
      messageHeader: 'This is info page'
    },
    {
      pageId: 'login.ftl',
      locale: { currentLanguageTag: 'ru' },
      message: { type: 'error', summary: 'This is an error' }
    },
    {
      pageId: 'login-page-expired.ftl',
      locale: { currentLanguageTag: 'ru' }
    },
    {
      pageId: 'login-reset-password.ftl',
      locale: { currentLanguageTag: 'ru' }
    },
    {
      pageId: 'login-update-password.ftl',
      locale: { currentLanguageTag: 'ru' }
    },
    {
      pageId: 'login-verify-email.ftl',
      locale: { currentLanguageTag: 'ru' },
      url: {
        loginRestartFlowUrl: 'https://register-restart.test'
      }
    },
    {
      pageId: 'register.ftl',
      locale: { currentLanguageTag: 'ru' },
      properties: {
        hasuraApiUrl: 'https://hasura.test',
        termsUrl: 'https://terms.test'
      },
      authorizedMailDomains: [
        'example.com',
        'another-example.com',
        '*.yet-another-example.com',
        '*.example.com',
        'hello-world.com'
      ],
      messagesPerField: {
        printIfExists: <T>(fieldName: string, className: T) => {
          console.log({ fieldName })
          return fieldName === 'email' ? className : undefined
        },
        existsError: (fieldName: string) => fieldName === 'email',
        get: (fieldName: string) => `Fake error for ${fieldName}`,
        exists: (fieldName: string) => fieldName === 'email'
      }
    },
    {
      pageId: 'update-email.ftl',
      locale: { currentLanguageTag: 'ru' }
    }
  ]
})

export const { kcContext } = getKcContext({
  // Uncomment to test the login page for development.
  //mockPageId: "login.ftl",
})

export type KcContext = NonNullable<ReturnType<typeof getKcContext>['kcContext']>
