import { createGetKcContext } from 'keycloakify/account'

export interface AccountExtension {
  attributes?: {
    city_fias_id: string
    patronymic?: string
    phone: string
    role: string
  }
  email: string
  firstName: string
  lastName: string
  username?: string
}

export type KcContextExtension =
  | { pageId: 'account.ftl'; account: AccountExtension; properties: { hasuraApiUrl: string; termsUrl: string } }
  | { pageId: 'password.ftl'; account: AccountExtension }

export const { getKcContext } = createGetKcContext<KcContextExtension>({
  mockData: [
    {
      pageId: 'account.ftl',
      account: {
        attributes: {
          city_fias_id: '8dea00e3-9aab-4d8e-887c-ef2aaa546456',
          patronymic: 'Евлампиевич',
          phone: '+7 900 000 00 00',
          role: 'smo'
        },
        firstName: 'Константинослав',
        lastName: 'Колоссальный'
      },
      features: {
        identityFederation: false,
        log: false
      },
      locale: { currentLanguageTag: 'ru' },
      message: {
        summary: 'Something went really wrong here',
        type: 'error'
      },
      properties: {
        hasuraApiUrl: 'https://dev-hasura.ekfgroup.com/hasura',
        termsUrl: 'https://terms.test'
      },
      realm: {
        userManagedAccessAllowed: false
      }
    },
    {
      pageId: 'password.ftl',
      locale: { currentLanguageTag: 'ru' }
    }
  ]
})

export const { kcContext } = getKcContext({
  //mockPageId: "password.ftl",
})

export type KcContext = NonNullable<ReturnType<typeof getKcContext>['kcContext']>
